.white-layout {
	background-color: #fff;
	background-color: var(--background_content);
	margin-bottom: 10px;
	padding: 4px;

	&:after {
		content: '';
		height: 1px;
		position: absolute;
		bottom: -1px;
		width: 100%;
		left: 0;
		background-color: rgba(0, 0, 0, 0.12);
		background-color: var(--field_border);
	}

	&-no-margin {
		margin-bottom: 0;
	}

	.geo {
		border-radius: 10px;
		border: 1px solid rgba(#000, .04);
		background: #eaedf0 url("../img/map.png") center no-repeat;
		background-size: contain;
		box-sizing: border-box;
		overflow: hidden;
		height: 240px;
		margin-left: 12px;
		margin-right: 12px;

		& > div {
			width: 100% !important;
			overflow: hidden;
			border-radius: 10px;

			& > ymaps {
				border-radius: 10px;
				overflow: hidden;
			}
		}
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {

	.white-layout:after {
		transform: scaleY(.5);
	}
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {

	.white-layout:after {
		transform: scaleY(.3333);
	}
}