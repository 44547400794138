.form-add-img {
	margin-top: 15px;
	font-family: 'SFProText', sans-serif;
	
	&__name {
		font-size: 14px;
		line-height: 1.29;
		letter-spacing: -0.15px;
		color: #909499;
		color: var(--text_secondary);
		margin-bottom: 7px;
	}
	
	&__box {
		display: flex;
		align-items: center;
	}
	
	&__img {
		width: 80px;
		height: 80px;
		min-width: 80px;
		min-height: 80px;
		background: #ecedf0 url("../img/icons/camera.svg") no-repeat center;
		border-radius: 8px;
		box-shadow: 0 0 1px rgba(0, 0, 0, 0.12);
		box-shadow: 0 0 1px var(--field_border);
	}
	
	&.circle {
		
		.form-add-img__img {
			border-radius: 50%;
			width: 56px;
			height: 56px;
			min-width: 56px;
			min-height: 56px;
		}
		
		.btn {
			width: 100px;
			line-height: 15px;
		}

		.btn-gray__del {
			width: 36px;
		}
	}
	
	&__info {
		margin-left: 12px;
	}
	
	&__text {
		font-size: 13px;
		line-height: 1.38;
		color: #909499;
		margin-top: 4px;
		margin-bottom: 11px;
	}
	
	.btn {
		width: 96px;
		height: 30px;
		font-size: 14px;
	}
	
	.btn-gray__del {
		width: 36px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url('../img/icons/delete_24.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 12px 15px;
		margin-left: 8px;
	}
	
	.group-box {
		.btn+ .btn {
			margin-left: 8px;
		}
	}
}


@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {

	.form-add-img__img {
		box-shadow: 0 0 0.5px rgba(0, 0, 0, 0.12);
		box-shadow: 0 0 0.5px var(--field_border);
	}
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {

	.form-add-img__img {
		box-shadow: 0 0 0.3333px rgba(0, 0, 0, 0.12);
		box-shadow: 0 0 0.3333px var(--field_border);
	}
}