.Input-select {
  position: relative;
}

.Input-select .FormField__border.focused {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.Input-select__input-block {
  position: relative;
}

.Input-select__input-block_icon {
  background: url("../img/icons/arrow_dark.svg") no-repeat;
  background-size: contain;
  position: absolute;
  width: 14px;
  height: 7px;
  right: 15px;
  top: 19px;
  z-index: 1;
}

.Input-select__list {
  display: none;
  font-size: 16px;
  letter-spacing: -0.1px;
  color: #000000;
  color: var(--text_primary);
  border-radius: 0 0 10px 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 0 0 1px var(--field_border);
  background: #f2f3f5;
  background: var(--field_background);
  border-top: none;
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  top: calc(100% - 1px);
}

.Input-select__list--open {
  display: flex;
  flex-direction: column;
}

.Input-select__list_option {
  padding: 10px 12px;
  cursor: pointer;
  font-size: 16px;
}

.Input-select__list_option:first-child {
  padding: 16px 12px 6px 12px;
}

.Input-select__list_option:last-child {
  padding: 6px 12px 16px 12px;
}

.Input-select--top .FormField__border.focused {
  border-radius: 0 0 16px 16px;
}

.Input-select--top .Input-select__list {
  bottom: calc(100% - 1px);
  top: auto;
  border-radius: 10px 10px 0 0;
}

.Input-select--top .Input-select__list--open {
  flex-direction: column-reverse;
}

.Input-select--top .Input-select__list_option:first-child {
  padding: 6px 12px 16px 12px;
}

.Input-select--top .Input-select__list_option:last-child {
  padding: 16px 12px 6px 12px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .Input-select__list {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 0 0 1px var(--field_border);
  }
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .Input-select__list {
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 0 0 1px var(--field_border);
  }
}
