.panel-complete {
  min-height: calc(100vh - 44px - 16px - var(--safe-area-inset-bottom) - var(--safe-area-inset-top));
  background: #fff;
  background: var(--background_content);
}

.panel-complete .qr-complete {
  height: calc(100vh - 48px - 48px - 48px - 48px - 16px - 44px - var(--safe-area-inset-top) - var(--safe-area-inset-bottom));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.panel-complete .qr-complete .icon-check {
  width: 44px;
  height: 44px;
  background-size: 20px;
  border: 3.4px solid #3f8ae0;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij48Zz48Zz4KCTxnPgoJCTxwYXRoIGQ9Ik01MDQuNTAyLDc1LjQ5NmMtOS45OTctOS45OTgtMjYuMjA1LTkuOTk4LTM2LjIwNCwwTDE2MS41OTQsMzgyLjIwM0w0My43MDIsMjY0LjMxMWMtOS45OTctOS45OTgtMjYuMjA1LTkuOTk3LTM2LjIwNCwwICAgIGMtOS45OTgsOS45OTctOS45OTgsMjYuMjA1LDAsMzYuMjAzbDEzNS45OTQsMTM1Ljk5MmM5Ljk5NCw5Ljk5NywyNi4yMTQsOS45OSwzNi4yMDQsMEw1MDQuNTAyLDExMS43ICAgIEM1MTQuNSwxMDEuNzAzLDUxNC40OTksODUuNDk0LDUwNC41MDIsNzUuNDk2eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgZmlsbD0iIzQ5ODZDQyIvPgoJPC9nPgo8L2c+PC9nPiA8L3N2Zz4K");
}

.panel-complete .qr-complete .complete-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.34px;
  color: #000000;
  color: var(--text_primary);
  margin-top: 16px;
}
