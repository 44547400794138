.line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.12);
  background-color: var(--field_border);
  transform: scaleY(1);
  width: 100%;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .line {
    transform: scaleY(0.5);
  }
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .line {
    transform: scaleY(0.3333);
  }
}
