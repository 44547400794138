.Retargeting {
  display: flex;
  padding: 12px 16px 14px;
}

.Retargeting--image {
  width: 28px;
  height: 28px;
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: 48% 52%;
  margin-top: -3px;
  margin-bottom: -3px;
}

.Retargeting--image.comment-ico {
  background-image: url("../img/icons/comment.svg");
}

.Retargeting--image.megafon-ico {
  background-image: url("../img/icons/megafon.svg");
}

.Retargeting--image.qr-code-ico {
  background-image: url("../img/icons/qr-code.svg");
}

.Retargeting--image.geo-ico {
  background-image: url("../img/icons/geo.svg");
}

.Retargeting--title {
  font-size: 17px;
  line-height: 1.29;
  letter-spacing: -0.41px;
  color: #000000;
  color: var(--text_primary);
  margin-left: 16px;
  flex: 1;
}

.Retargeting--icon {
  margin-top: 2px;
}
