.footer-btn {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	background: var(--background_content);
	padding: 12px 16px;
	position: relative;

	&:before {
		content: '';
		height: 1px;
		position: absolute;
		top: -1px;
		width: 100%;
		left: 0;
		background-color: rgba(0, 0, 0, 0.12);
		background-color: var(--field_border);
	}

	&.fixed-pos {
		height: 36px;
		position: fixed;
		z-index: 2;
		bottom: 0;
		left: 0;
		right: 0;
		box-sizing: content-box;
		padding-bottom: calc(12px + var(--safe-area-inset-bottom));
	}

	& > button {
		width: 50%;
	}

	button + button {
		margin-left: 8px;
	}

	.hide-btn {
		height: 36px;
		width: 44px;
		background-image: url("../img/icons/arrow_dark.svg");
		background-repeat: no-repeat;
		background-position: 12px 14px;
		background-size: 15px;

		&.is-hide {
			background-image: url("../img/icons/arrow_dark_revert.svg");
			background-position: 12px 12px;
		}
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {

	.footer-btn:before {
		transform: scaleY(.5);
	}
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {

	.footer-btn:before {
		transform: scaleY(.3333);
	}
}