.header-page {
	background-color: var(--background_content);
	padding-left: 15px;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;

	&_ios {
		margin-top: calc(-52px - var(--safe-area-inset-top));
		padding-top: calc(52px + var(--safe-area-inset-top));
	}

	&_android {
		margin-top: calc(-56px - var(--safe-area-inset-top));
		padding-top: calc(56px + var(--safe-area-inset-top));
	}

	&__title {
		font-family: TTCommons;
		font-size: 30px;
		font-weight: 600;
		color: #528bcc;
		color: var(--accent);
	}

	&__icon {
		width: 12px;
		height: 6px;
		background: url('../img/icons/dropdown_16.svg') center no-repeat;
		background-size: contain;
		margin-left: 6px;
		margin-bottom: 3px;
	}
}

body[scheme="client_dark"] {

	.header-page__icon  {
		background: url('../img/icons/dropdown_16_light_blue.svg') center no-repeat;
	}
}