.form-radio-icon {
	position: relative;
	
	& + & {
		margin-left: 10px;
	}
	
	input[type="radio"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		outline: 0 !important;
		
		& + label {
			position: relative;
			width: 64px;
			height: 64px;
			border-radius: 8px;
			border: 1px solid #e7e8ec;
			background-color: #fff;
			display: block;
			cursor: pointer;
			box-sizing: border-box;
			
			&:before {
				content: '';
				width: 64px;
				height: 64px;
				border-radius: 8px;
				border: 2px solid #e7e8ec;
				background-color: #fff;
				display: inline-block;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;
				box-sizing: border-box;
			}
		}
		
		&:checked+label:before {
			opacity: 1;
			border-color: #5c9ce6;
		}
	}
	
	&__img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 34px;
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	&__none {
		background: url('../img/icons/noicon.svg') no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}
	
	&__vk {
		background: url('../img/icons/vk blue.svg') no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}
	
	&__vk-black {
		background: url('../img/icons/vk black.svg') no-repeat;
		-webkit-background-size: contain;
		background-size: contain;
	}
	
	&__dash {
		& input[type="radio"] + label{
			border: 1px dashed #6190ca;
		}
	}
	
	&__add {
		color: #6190ca;
	}

	&_div-upload-icon {
		position: relative;
		width: 64px;
		height: 64px;
		border-radius: 6px;
		display: block;
		cursor: pointer;
		box-sizing: border-box;
		border: 2px dashed #6190ca!important;
		background: url("../img/icons/upload.svg") no-repeat center;
		background-size: 20px;
	}
}