.tab-type {
	font-family: 'SFProText', sans-serif;
	
	&__header {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		overflow: hidden;
		overflow-x: auto;
		margin: 0 -16px;
		padding-bottom: 10px;
		
		&::-webkit-scrollbar {
			height: 0;
		}
	}
	
	&__title {
		font-size: 17px;
		font-weight: 500;
		letter-spacing: -0.24px;
		text-align: center;
		color: #99a2ad;
		color: var(--tabbar_inactive_icon);
		white-space: nowrap;
		padding: 0 13px;
		position: relative;
		cursor: pointer;
		
		&--active {
			color: #000;
			color: var(--text_primary);
			font-weight: 600;
			
			&:before {
				content: '';
				position: absolute;
				left: 25%;
				right: 25%;
				bottom: -10px;
				margin: 0 auto;
				height: 2px;
				border-radius: 2px;
				background: #528bcc;
				background: var(--accent);
			}
		}
	}
	
	&__body {
		margin-top: 18px;
	}

	&__item {
		display: none;

		&--active {
			display: block;
		}
	}
}