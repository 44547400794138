.modal-cells {
	box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: inset 0 1px 0 var(--field_border);

	.cell-style {
		display: flex;
		align-items: center;
		height: 34px;
	}

	&:after {
		content: none;
	}

	.Cell:last-child {
		.Cell__before::before {
			content: none !important;
		}
	}

	&__link, &__wifi, &__contact, &__text, &__event, &__geo {
		background-position: center;
		background-repeat: no-repeat;
		width: 24px;
		height: 24px;
		margin-right: 20px;
	}

	&__link {
		background-image: url("../img/icons/link_blue.svg");
	}

	&__wifi {
		background-image: url("../img/icons/wifi_blue.svg");
	}

	&__contact {
		background-image: url("../img/icons/card_blue.svg");
	}

	&__text {
		background-image: url("../img/icons/text_blue.svg");
	}

	&__event {
		background-image: url("../img/icons/event_blue.svg");
	}

	&__geo {
		background-image: url("../img/icons/geo_blue.svg");
	}

	.Cell__aside {
		color: #528bcc;
		color: var(--accent);
	}
}


body[scheme="client_dark"] .modal-cells {

	&__link {
		background-image: url("../img/icons/link_light_blue.svg");
	}

	&__wifi {
		background-image: url("../img/icons/wifi_light_blue.svg");
	}

	&__contact {
		background-image: url("../img/icons/card_light_blue.svg");
	}

	&__text {
		background-image: url("../img/icons/text_light_blue.svg");
	}

	&__event {
		background-image: url("../img/icons/event_light_blue.svg");
	}

	&__geo {
		background-image: url("../img/icons/geo_light_blue.svg");
	}

	.Cell__aside {
		color: #528bcc;
		color: var(--accent);
	}
}


@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {

	.modal-cells {
		box-shadow: inset 0 0.5px 0 rgba(0, 0, 0, 0.12);
		box-shadow: inset 0 0.5px 0 var(--field_border);
	}
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {

	.modal-cells {
		box-shadow: inset 0 0.3333px 0 rgba(0, 0, 0, 0.12);
		box-shadow: inset 0 0.3333px 0 var(--field_border);
	}
}