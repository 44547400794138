@font-face { font-family: "TTCommons"; font-weight: 400; 	font-style: normal; src: url("../fonts/TT Commons Medium.otf"); }
@font-face { font-family: "TTCommons"; font-weight: 600; 	font-style: normal; src: url("../fonts/TT Commons DemiBold.ttf"); }
@font-face { font-family: "SFProText"; font-weight: 400;	font-style: normal;	src: url("../fonts/SFProText-Regular.ttf"); }
@font-face { font-family: "SFProText"; font-weight: 600;	font-style: normal;	src: url("../fonts/SFProText-Medium.ttf"); }

body {
	padding: 0;
	background: #edeef0;
	font-family: 'SFProText', -apple-system, 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', 'Geneva', 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif;
	margin: 0 auto;
}

//*, *:before, *:after {
//	box-sizing: border-box;
//}

#panel-header-panelMain .PanelHeader-bg:after {
	display: none;
}

#panel-header-panelMain .PanelHeader__left, #panel-header-panelMain .PanelHeader__right,  #panel-header-panelMain .PanelHeader-right {
	background-color: #f9f9f9;
	background-color: var(--background_light);
	height: 0;
}

#panel-header-panelMain .PanelHeader-bg {
	background-color: #f9f9f9;
	background-color: var(--background_light);
	height: 0;
}

#panelMain .Panel__in-before {
	height: 0;
}

#panelMain .Panel__in {
	background-color: #f9f9f9;
	background-color: var(--background_light);
}

#panel-header-panelCustomize {

	.PanelHeader__left, .PanelHeader__right, .PanelHeader-left-in, .PanelHeader-right, .PanelHeader-content, .PanelHeader-bg {
		background-color: rgba(255,255,255,0);
		background-color: var(--background_page);
	}

	.PanelHeader-content--brand, .PanelHeader-left-in {
		color: #2c2d2e;
		color: var(--text_muted);
	}
}

#panel-header-panelFeedback {

	.PanelHeader__left, .PanelHeader__right, .PanelHeader-left-in, .PanelHeader-right, .PanelHeader-content, .PanelHeader-bg {
		background-color: #fff;
		background-color: var(--background_content);
	}

	.PanelHeader-content--brand, .PanelHeader-left-in {
		color: #2c2d2e;
		color: var(--text_muted);
	}

	.PanelHeader-ios .PanelHeader-bg::after {
		background: #d7d8d9;
		background: var(--separator_common);
	}
}

.no-border {

	.View__header {
		pointer-events: none;
	}
}

.qr-box {
	display: flex;
	justify-content: center;
	padding-bottom: 12px;
	width: 100%;

	&_empty {
		opacity: .5;
	}

	& > div > span > svg {
		border-radius: 12px;
		box-shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.12);
		background-color: #fff;
		margin-bottom: -15px;
	}

	& > div {
		transform: scale(0.88);
	}
}

.group-box {
	display: flex;
}

.flex-direction-column {
	flex-direction: column;
}

.mt-0 {
	margin-top: 0!important;
}

.mb-0 {
	margin-bottom: 0!important;
}

.mb-5 {
	margin-bottom: 5px!important;
}

.mb-15 {
	margin-bottom: 15px;
}

.mt-15 {
	margin-top: 15px!important;
}

.no-border {

	&:after {
		display: none;
	}
}

.hide-it {
	display: none;
}

.modal-opened {
	overflow: hidden;
}

.full-height {
	min-height: calc(100vh - 126px);
}

.ymaps-2-1-74-search_layout_panel.ymaps-2-1-74-search_layout_panel {
	//transform: translateY(0px)!important;
	box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.ymaps-2-1-74-searchbox__panel-layout .ymaps-2-1-74-searchbox__fold-button-cell {
	//display: none;
}

.ymaps-2-1-74-searchbox__normal-layout {
	width: calc(100% - 10px)!important;
}

.two {
	display: flex;
	flex-wrap: wrap;

	& .form-input__name {
		margin-left: 12px;
	}

	& > .form-input {
		margin-top: 0!important;
		flex: 1;
		width: 100%;
		position: relative;

		& > .Input {
			overflow: hidden;
			white-space: nowrap;
		}

		&.first-input {
			min-width: 170px;

			.Input {
				margin-right: 6px;
			}
		}

		&.last-input {

			.Input {
				margin-left: 6px;
			}

			.form-input__name {
				margin-left: 6px;
			}
		}
	}
}

.Quote {

	& + .Quote {
		margin-top: 32px;
	}

	p:last-child {
		margin: 0;
	}

	&__title {
		font-size: 21px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: 0.86;
		letter-spacing: normal;
		color: #2c2d2e;
		color: var(--text_muted);
		font-family: TTCommons;
	}

	&__text {
		font-size: 15px;
		line-height: 1.47;
		letter-spacing: -0.24px;
		color: #000000;
		color: var(--text_primary);
		margin-top: 10px;
	}
}

input, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.Icon.black {
	color: #2c2d2e;
	color: var(--text_muted);
}

.Icon--24 {
	box-sizing: unset;
}

.qr-doodle {
	width: 250px;
	height: 250px;
	transform: scale(0.88);
	border-radius: 12px;
	box-shadow: 0 4px 26px 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
	margin-left: auto;
	margin-right: auto;
	background-image: url("../img/qr-doodle.png");
	background-size: 186.8px;
	background-repeat: no-repeat;
	background-position: center;
}

.advanced-toggle {
	font-size: 15px;
	letter-spacing: -0.24px;
	color: #000000;
	display: flex;
	justify-content: space-between;
	align-items: center;

	div {
		color: #000;
		color: var(--text_primary);
	}
}

.advanced-layout {
	margin-left: -12px;
	margin-right: -12px;
}

#qr-block {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -15px;
}

.date-error {
	font-size: 13px;
	letter-spacing: -0.08px;
	color: #e64646;
	padding: 8px 12px 0;
	margin-top: -22px;
}

.fake-footer {
    height: 60px;
}

.no-scroll-yet .View__header {
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s var(--ios-easing);
}

.scroll-smooth .View__header {
	transition: opacity 0.3s var(--ios-easing);
}

.bottom-abs {
	position: absolute;
	bottom: 0;
	width: 100%;
	box-sizing: border-box;
	z-index: 10000;
}

#panelFeedback {
	min-height: 420px;
}

.Top-about {
	height: 76px;
	background-color: #fff;
	background-color: var(--background_content);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 16px;

	&-btn {
		border-radius: 10px;
		box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06), 0 0 2px 0 rgba(0, 0, 0, 0.06);
		box-shadow: 0 2px 24px 0 var(--input_border), 0 0 2px 0 var(--input_border);
		background-color: #ffffff;
		background-color: var(--background_content);
		height: 44px;
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
		color: #000;
		color: var(--text_primary);
	}
}

.icon-about {
	margin-right: 7px;
	transform: scale(0.8);
	color: #528bcc;
	color: var(--accent);
}


.mb-108  {
	textarea {
		margin-bottom: 108px;
	}
}

.PanelHeader__content, .PanelHeader-content {
	background: transparent !important;
}

.HeaderButton {
	padding: 0 5px;
}

@import "complete";
@import "customize";
@import "header";
@import "btn";
@import "main-menu";
@import "header-page";
@import "white-block";
@import "white-layout";
@import "form-input";
@import "form-checkbox";
@import "footer-btn";
@import "footer-download";
@import "tab-type";
@import "tab-second";
@import "group-form";
@import "form-radio-color";
@import "form-add-img";
@import "form-radio";
@import "form-radio-icon";
@import "form-toggle";
@import "form-textarea";
@import "feedback-block";
@import "input-select";

@import "modal";
