.ColorPicker {
  margin-top: 12px;
  margin-bottom: -4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.12), 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 var(--field_border), 0 -1px 0 var(--field_border);
}

.ColorPicker.no-bottom-border {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 -1px 0 var(--field_border);
}

.ColorPicker.no-bottom-border .ColorPicker__content {
  padding-bottom: 0;
}

.ColorPicker__header {
  padding: 3px 4px;
  font-size: 13px;
  letter-spacing: -0.1px;
  color: #000;
  display: flex;
  font-family: 'SFProText', sans-serif;
}

.ColorPicker__content {
  padding-bottom: 16px;
}

.ColorPicker__content_panel {
  display: none;
}

.ColorPicker__content_panel--selected {
  display: block;
}

.ColorPicker__content.alone {
  padding-top: 16px;
}

.ColorPicker__footer {
  padding: 9px 16px 16px;
  display: flex;
}

.ColorPicker__footer .btn + .btn {
  margin-left: 8px;
}

.ColorPicker__footer .btn {
  flex: 1;
}

.ColorPicker__title {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.32px;
  text-align: center;
  color: #76787a;
  color: var(--panel_tab_inactive_text);
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  padding: 15px 14px 14px 14px;
}

.ColorPicker__title span {
  position: relative;
}

.ColorPicker__title:first-child {
  margin-left: -8px;
}

.ColorPicker__title:last-child {
  padding-right: 22px;
}

.ColorPicker__title--active {
  color: #5d5f61;
  color: var(--panel_tab_active_text);
}

.ColorPicker__title--active:last-child:before {
  right: 12px;
}

.ColorPicker__title--active:before {
  content: '';
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 8px;
  top: 8px;
  border-radius: 16px;
  background: #ebedf0;
  background: var(--panel_tab_active_background);
}

.chrome-picker {
  width: 100% !important;
  box-shadow: none !important;
  background: #fff !important;
  background: var(--background_content) !important;
}

.chrome-picker .hue-horizontal > div > div {
  position: absolute;
  top: -2px;
}

.chrome-picker > div:first-child {
  border-radius: 8px !important;
  padding-bottom: initial !important;
  height: 140px;
}

.chrome-picker > div:last-child {
  padding: 16px 0 0 !important;
  position: relative;
}

.chrome-picker > div:last-child > div:first-child > div:first-child {
  position: absolute;
  left: 9px;
  bottom: 8px;
  width: 24px !important;
  height: 24px;
  border-radius: 4px;
}

.chrome-picker > div:last-child > div:first-child > div:first-child > div {
  width: 24px !important;
  height: 24px !important;
  border-radius: 4px !important;
}

.chrome-picker > div:last-child > div:first-child > div:first-child > div > div:first-child {
  border-radius: 4px !important;
  box-shadow: none !important;
  border: solid 0.5px rgba(0, 0, 0, 0.08);
}

.chrome-picker > div:last-child > div:first-child > div:last-child {
  height: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.chrome-picker > div:last-child > div:first-child > div:last-child > div:first-child {
  height: 100% !important;
}

.chrome-picker > div:last-child > div:first-child > div:last-child > div:first-child > div > div > div > div {
  width: 16px !important;
  height: 16px !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24) !important;
  border: solid 2px #ffffff !important;
  background-color: transparent !important;
  transform: translate(-6px, 0px) !important;
}

.chrome-picker > div:last-child > div:last-child input {
  height: 40px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  outline: none;
  font-family: SFProText;
  font-size: 15px !important;
  line-height: 1.33;
  letter-spacing: -0.24px;
  text-align: left !important;
  padding-left: 44px;
  box-sizing: border-box;
  color: #000 !important;
  color: var(--text_primary) !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border: 1px solid var(--field_border) !important;
  background: #f2f3f5 !important;
  background: var(--field_background) !important;
}

.chrome-picker > div .flexbox-fix:last-child span {
  display: none !important;
}

.chrome-picker > div .flexbox-fix:last-child > div:last-child {
  display: none !important;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  .ColorPicker {
    box-shadow: 0 0.5px 0 rgba(0, 0, 0, 0.12), 0 -0.5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0.5px 0 var(--field_border), 0 -0.5px 0 var(--field_border);
  }
  .ColorPicker.no-bottom-border {
    box-shadow: 0 -0.5px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 -0.5px 0 var(--field_border);
  }
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
  .ColorPicker {
    box-shadow: 0 0.3333px 0 rgba(0, 0, 0, 0.12), 0 -0.3333px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0.3333px 0 var(--field_border), 0 -0.3333px 0 var(--field_border);
  }
  .ColorPicker.no-bottom-border {
    box-shadow: 0 -0.3333px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 -0.3333px 0 var(--field_border);
  }
}
