.form-input {
	margin-top: 23px;
	font-family: 'SFProText', sans-serif;

	&__name {
		font-size: 14px;
		line-height: 1.29;
		letter-spacing: -0.15px;
		color: #909499;
		color: var(--text_secondary);

		margin-bottom: 7px;

		span {
			color: #828282;
		}
	}

	> span {
		font-size: 13px;
		line-height: 1.23;
		letter-spacing: -0.08px;
		color: #909499;
		margin-top: 8px;
		display: block;
	}

	&__hint {
		margin-top: 10px;
		font-size: 12.5px;
		letter-spacing: -0.1px;
		color: #828282;
	}

	&.with-placeholder {
		position: relative;

		.input-placeholder {
			position: absolute;
			bottom: 12px;
			left: 25px;
			font-size: 16px;
			color: #909499;;
			color: var(--field_text_placeholder);
			pointer-events: none;
			z-index: 1;
			right: 25px;
			white-space: nowrap;
			overflow: hidden;
		}

		&.left {
			.input-placeholder {
				left: 20px;
			}
		}
	}
}