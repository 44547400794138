.form-radio {
	position: relative;
	display: flex;

	& + & {
		margin-left: 22px;
	}

	input[type="radio"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		outline: 0 !important;

		&:hover + label {
			color: rgba(#000, .8);
		}

		& +label {
			cursor: pointer;
			position: relative;
			padding-left: 35px;
			padding-top: 15px;
			padding-bottom: 15px;
			font-size: 15px;
			line-height: 1.23;
			letter-spacing: -0.24px;
			color: #000000;

			&:before {
				content: '';
				width: 20px;
				height: 20px;
				border-radius: 50%;
				position: absolute;
				top: 14px;
				left: 0;
				border: solid 1px #c1c9d1;
				background-color: #ffffff;
				box-sizing: border-box;
			}

			&:after {
				content: '';
				position: absolute;
				top: 17px;
				left: 3px;
				background: #3f8ae0;
				opacity: 0;
				transform: scale(0);
				width: 14px;
				height: 14px;
				border-radius: 50%;
			}
		}

		&:checked + label:after {
			opacity: 1;
			transform: scale(1);
		}

		&:checked + label:before {
			border-color: #3f8ae0;
		}
	}
}