.form-checkbox {
	position: relative;
	margin-top: 25px;
	font-family: 'SFProText', sans-serif;
	display: flex;
	flex-direction: column;
	
	input[type="checkbox"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		cursor: pointer;
		outline: 0 !important;
		
		&:hover+label {
			color: rgba(#000, .8);
		}
		
		& + label {
			cursor: pointer;
			position: relative;
			padding-left: 40px;
			font-size: 15px;
			line-height: 1.33;
			letter-spacing: -0.24px;
			color: #000000;
			
			&:before {
				content: '';
				width: 20px;
				height: 20px;
				border-radius: 3px;
				border: 1px solid #c1c9d1;
				background-color: #ffffff;
				display: inline-block;
				position: absolute;
				top: -1px;
				left: 0;
			}
			
			&:after {
				content: '';
				position: absolute;
				top: -1px;
				left: 0;
				border-radius: 3px;
				opacity: 0;
				transform: scale(0);
				width: 20px;
				height: 20px;
				background: #3f8ae0 url('../img/icons/upload.svg') 2px center no-repeat;
				background-size: 15px;
			}
		}
		
		&:checked+label:after {
			opacity: 1;
			transform: scale(1);
		}
	}
	
	span{
		margin-top: 3px;
		font-size: 14px;
		line-height: 1.29;
		letter-spacing: -0.15px;
		color: #909499;
		margin-left: 37px;
	}
}