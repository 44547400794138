.white-block {
	background-color: #ebedf0;
	margin-bottom: 10px;
	position: relative;

	&-no-margin {
		margin-bottom: 0;
	}

	&__inner {
		position: relative;
		background-color: #fff;
		background-color: var(--background_content);
		padding: 19px 16px 22px;

		&.pad-b-0 {
			padding-bottom: 24px;
		}

		&--full {
			align-items: center;
			display: flex;
			justify-content: center;
		}
		
		&:before {
			top: 0;
		}
		
		&:after {
			bottom: 0;
		}
	}
	
	.form-input:first-child {
		margin-top: 0;
	}
	
	&__info {
		font-size: 13px;
		line-height: 1.38;
		letter-spacing: -0.1px;
		color: #000000;
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid #e7e8ec;
		
		p {
			margin: 0 0 10px;
			
		}
	}

	&:after, &:before {
		content: '';
		height: 1px;
		position: absolute;
		bottom: -1px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.12);
		background-color: var(--field_border);
	}

	&:before {
		top: -1px;
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {

	.white-block:after, .white-block:before {
		transform: scaleY(.5);
	}
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {

	.white-block:after, .white-block:before {
		transform: scaleY(.3333);
	}
}