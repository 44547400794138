.main-menu {
	font-family: 'SFProText', sans-serif;

	&__item {
		display: flex;
		background: #fff;
		background: var(--background_content);
		padding: 19px 0 0 16px;
	}
	
	&__inner {
		display: flex;
		flex-direction: column;
		padding-bottom: 16px;
		text-decoration: none;
		position: relative;
		width: 100%;

		&:after {
			content: '';
			height: 1px;
			position: absolute;
			bottom: -1px;
			width: 100%;
			left: 0;
			background-color: rgba(0, 0, 0, 0.12);
			background-color: var(--field_border);
		}
	}
	
	&__link {
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}
	
	&__name {
		font-size: 16px;
		font-weight: 600;
		color: #2c2d2e;
		color: var(--text_muted);
		letter-spacing: -0.32px;
		margin-left: 16px;
	}
	
	&__info {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: -0.15px;
		color: #909499;
		color: var(--text_secondary);
		margin-left: 40px;
		margin-right: 16px;
	}
}

.icon-menu {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 19px;
	width: 24px;
	height: 24px;
}

.icon-menu {

	&.link {
		background-image: url("../img/icons/link_blue.svg");
	}

	&.wifi {
		background-image: url("../img/icons/wifi_blue.svg");
	}

	&.contact {
		background-image: url("../img/icons/card_blue.svg");
	}

	&.text {
		background-image: url("../img/icons/text_blue.svg");
	}

	&.event {
		background-image: url("../img/icons/event_blue.svg");
	}

	&.geo {
		background-image: url("../img/icons/geo_blue.svg");
	}
}

body[scheme="client_dark"] .icon-menu {

	&.link {
		background-image: url("../img/icons/link_light_blue.svg");
	}

	&.wifi {
		background-image: url("../img/icons/wifi_light_blue.svg");
	}

	&.contact {
		background-image: url("../img/icons/card_light_blue.svg");
	}

	&.text {
		background-image: url("../img/icons/text_light_blue.svg");
	}

	&.event {
		background-image: url("../img/icons/event_light_blue.svg");
	}

	&.geo {
		background-image: url("../img/icons/geo_light_blue.svg");
	}
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {

	.main-menu__inner:after {
		transform: scaleY(.5);
	}
}

@media (-webkit-min-device-pixel-ratio: 3), (min-resolution: 3dppx) {

	.main-menu__inner:after {
		transform: scaleY(.3333);
	}
}