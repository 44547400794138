.tab-second {
	font-family: 'SFProText', sans-serif;
	
	&__header {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		overflow: hidden;
		overflow-x: auto;
		margin: -9px -16px;
		padding-bottom: 10px;
		
		&::-webkit-scrollbar {
			height: 0;
		}
	}
	
	&__title {
		font-size: 15px;
		font-weight: 500;
		letter-spacing: -0.32px;
		text-align: center;
		color: #76787a;
		color: var(--panel_tab_inactive_text);
		white-space: nowrap;
		position: relative;
		cursor: pointer;
		padding: 15px 14px 14px 14px;

		span {
			position: relative;
		}

		&:first-child {
			margin-left: 8px;
		}

		&:last-child {
			padding-right: 22px;
		}
		
		&--active {
			color: #5d5f61;
			color: var(--panel_tab_active_text);

			&:last-child {

				&:before {
					right: 12px;
				}
			}
			
			&:before {
				content: '';
				position: absolute;
				left: 4px;
				right: 4px;
				bottom: 8px;
				top: 8px;
				border-radius: 16px;
				background: #ebedf0;
				background: var(--panel_tab_active_background);
			}
		}
	}
	
	&__body {
		//margin-top: 18px;
	}

	&__item {
		display: none;

		&--active {
			display: block;
		}
	}
}